var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "a-card",
        { attrs: { bordered: false } },
        [
          _c(
            "div",
            { staticClass: "table-page-search-wrapper" },
            [
              _c(
                "div",
                { staticClass: "exit-btn" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.saveAexit },
                    },
                    [_vm._v("保存配置并退出")]
                  ),
                ],
                1
              ),
              _c(
                "a-form",
                { attrs: { layout: "inline" } },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: 48 } },
                    [
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            { attrs: { label: "CPU架构", prop: "dcCpuArch" } },
                            [
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择CPU架构",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.queryParam.dcCpuArch,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.queryParam, "dcCpuArch", $$v)
                                    },
                                    expression: "queryParam.dcCpuArch",
                                  },
                                },
                                _vm._l(_vm.cpuArchOptions, function (item) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: item.dictValue,
                                      attrs: { value: item.dictValue },
                                    },
                                    [_vm._v(" " + _vm._s(item.dictLabel) + " ")]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { md: 8, sm: 24 } },
                        [
                          _c(
                            "a-form-item",
                            {
                              attrs: { label: "地盘类型", prop: "chassisType" },
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    placeholder: "请选择地盘类型",
                                    "allow-clear": "",
                                  },
                                  model: {
                                    value: _vm.queryParam.chassisType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.queryParam,
                                        "chassisType",
                                        $$v
                                      )
                                    },
                                    expression: "queryParam.chassisType",
                                  },
                                },
                                _vm._l(
                                  _vm.vehicleChassisTypeOptions,
                                  function (item) {
                                    return _c(
                                      "a-select-option",
                                      {
                                        key: item.dictValue,
                                        attrs: { value: item.dictValue },
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(item.dictLabel) + " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("a-col", { attrs: { md: 8, sm: 24 } }, [
                        _c(
                          "span",
                          {
                            staticClass: "table-page-search-submitButtons",
                            style:
                              (_vm.advanced && {
                                float: "right",
                                overflow: "hidden",
                              }) ||
                              {},
                          },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary" },
                                on: { click: _vm.handleQuery },
                              },
                              [
                                _c("a-icon", { attrs: { type: "search" } }),
                                _vm._v("查询"),
                              ],
                              1
                            ),
                            _c(
                              "a-button",
                              {
                                staticStyle: { "margin-left": "8px" },
                                on: { click: _vm.resetQuery },
                              },
                              [
                                _c("a-icon", { attrs: { type: "redo" } }),
                                _vm._v("重置"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-operations" },
            [
              _c("a-button", { on: { click: _vm.resetCheckEvent } }, [
                _vm._v("清空选择"),
              ]),
              _c(
                "a-button",
                {
                  staticStyle: { "margin-left": "8px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.checkAllEvent },
                },
                [_vm._v("全选符合条件车辆")]
              ),
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              loading: _vm.loading,
              size: _vm.tableSize,
              rowKey: "id",
              columns: _vm.columns,
              "data-source": _vm.list,
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "online",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(" " + _vm._s(_vm.onlineMap[record.online]) + " "),
                  ])
                },
              },
              {
                key: "deviceTime",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      " " + _vm._s(_vm.parseTime(record.deviceTime)) + " "
                    ),
                  ])
                },
              },
              {
                key: "operation",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    [
                      _c("a-checkbox", {
                        attrs: { checked: record.isSelected },
                        on: {
                          change: function ($event) {
                            return _vm.onChange($event, record)
                          },
                        },
                      }),
                    ],
                    1
                  )
                },
              },
            ]),
          }),
          _c("a-pagination", {
            staticClass: "ant-table-pagination",
            attrs: {
              "show-size-changer": "",
              "show-quick-jumper": "",
              current: _vm.queryParam.pageNum,
              total: _vm.total,
              "page-size": _vm.queryParam.pageSize,
              showTotal: function (total) {
                return "共 " + total + " 条"
              },
            },
            on: {
              showSizeChange: _vm.onShowSizeChange,
              change: _vm.changeSize,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }