<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <div class="exit-btn"><a-button type="primary" @click="saveAexit">保存配置并退出</a-button></div>
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="CPU架构" prop="dcCpuArch">
                <a-select placeholder="请选择CPU架构" v-model="queryParam.dcCpuArch" style="width: 100%" allow-clear>
                  <a-select-option v-for="item in cpuArchOptions" :key="item.dictValue" :value="item.dictValue">
                    {{ item.dictLabel }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="地盘类型" prop="chassisType">
                <a-select placeholder="请选择地盘类型" v-model="queryParam.chassisType" style="width: 100%" allow-clear>
                  <a-select-option
                    v-for="item in vehicleChassisTypeOptions"
                    :key="item.dictValue"
                    :value="item.dictValue"
                  >
                    {{ item.dictLabel }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operations">
        <a-button @click="resetCheckEvent">清空选择</a-button>
        <a-button type="primary" style="margin-left: 8px" @click="checkAllEvent">全选符合条件车辆</a-button>
      </div>
      <!-- 增加修改 -->
      <!-- <create-form ref="createForm" @ok="getList" /> -->
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <span slot="online" slot-scope="text, record">
          {{ onlineMap[record.online] }}
        </span>
        <span slot="deviceTime" slot-scope="text, record">
          {{ parseTime(record.deviceTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-checkbox :checked="record.isSelected" @change="onChange($event, record)"></a-checkbox>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { getVehicles, configOTA } from '@/api/device/ota'

export default {
  name: 'DeviceStatus',
  components: {},
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        id: this.$route.query.id,
        otaVersion: this.$route.query.otaVersion,
        chassisType: undefined,
        dcCpuArch: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        {
          title: '车辆编号',
          dataIndex: 'vehicleId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车辆内部ID',
          dataIndex: 'vehicleInnerId',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '车端MAC地址',
          dataIndex: 'mqttTopic',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '当前智驾版本号',
          dataIndex: 'otaVersion',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '最近升级完成时间',
          dataIndex: 'otaUpdateTime',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'CPU架构',
          dataIndex: 'dcCpuArch',
          ellipsis: true,
          customRender: (text, record, index) => {
            const status = record.dcCpuArch
            if (status === 0) {
              return 'X86'
            } else if (status === 1) {
              return 'ARM'
            } else if (status === -1) {
              return '全部'
            } else {
              return ''
            }
          },
          align: 'center'
        },
        {
          title: '底盘选择',
          dataIndex: 'chassisType',
          ellipsis: true,
          customRender: (text, record, index) => {
            for (const item of this.vehicleChassisTypeOptions) {
              if (item.dictValue === record.chassisType + '') {
                return item.dictLabel
              }
            }
          },
          align: 'center'
        },
        {
          title: '选择',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      onlineMap: {
        0: '离线',
        1: '在线'
      },
      cpuArchOptions: [
        { dictValue: '', dictLabel: '全部' },
        { dictValue: '0', dictLabel: 'X86' },
        { dictValue: '1', dictLabel: 'ARM' }
      ],
      vehicleChassisTypeOptions: []
    }
  },
  filters: {},
  created() {
    this.getDicts('vehicle_chassisType').then((response) => {
      this.vehicleChassisTypeOptions = response.data
    })
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询设备状态列表 */
    getList() {
      this.loading = true
      getVehicles(this.queryParam).then((response) => {
        this.list = response.data
        const otaVersion = this.$route.query.otaVersion
        for (const item of this.list) {
          if (item.otaVersionTarget === otaVersion) {
            this.$set(item, 'isSelected', true)
          } else {
            this.$set(item, 'isSelected', false)
          }
        }
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    checkAllEvent() {
      for (const lItem of this.list) {
        this.$set(lItem, 'isSelected', true)
      }
    },
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        id: this.$route.query.id,
        otaVersion: this.$route.query.otaVersion,
        chassisType: undefined,
        dcCpuArch: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    /** 清空选择操作 */
    resetCheckEvent() {
      for (const lItem of this.list) {
        this.$set(lItem, 'isSelected', false)
      }
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    onChange(e, item) {
      console.log('checkbox onChange:', arguments)
      for (const lItem of this.list) {
        if (lItem.id === item.id) {
          this.$set(lItem, 'isSelected', e.target.checked)
        }
      }
    },
    saveAexit() {
      const otaVehicles = []
      for (const item of this.list) {
        otaVehicles.push({
          vehicleId: item.vehicleId,
          selected: item.isSelected ? 1 : 0
        })
      }
      configOTA({
        id: this.$route.query.id,
        otaVehicles
      }).then((res) => {
        this.$router.replace('/ota/info')
      })
    }
  }
}
</script>
<style scoped lang="less">
.exit-btn {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}
</style>
