/*
 * @Author: linjituan linjituan@revolution.com
 * @Date: 2023-04-11 11:46:57
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2023-04-14 14:27:30
 * @FilePath: \ra-web-admin\src\api\device\ota.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

export function getOtaList(params) {
  return request({
    url: '/iot/otaInfo/list',
    method: 'get',
    params: params
  })
}

export function configOTA(data) {
  return request({
    url: '/iot/otaInfo/configOTA',
    method: 'post',
    data
  })
}

export function getVehicles(params) {
  return request({
    url: '/iot/otaInfo/getVehicles',
    method: 'get',
    params: params
  })
}
